<template>
  <b-row class="match-height">
    <b-col md="2" sm="12">
      <b-card>
        <div class="d-flex justify-content-center">
          <b-img
            :src="require('@/assets/images/logo/logo.png')"
            alt="logo"
            width="240%"
          />
        </div>
      </b-card>
    </b-col>

    <b-col md="10" sm="12">
      <b-card>
        <p>Murp Company</p>
        <p>เบอร์โทรศัพท์ : 02-xxx-xxxx</p>
        <p>Line@ : @murpthai</p>
        <p>Email : murpthai@gmail.com</p>
        <p>Facebook : murpthai</p>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BCard, BRow, BCol, BImg } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
  },
  setup() {
    return {};
  },
};
</script>
